import { render, staticRenderFns } from "./EllipsisText.vue?vue&type=template&id=93e92290&scoped=true&"
import script from "./EllipsisText.vue?vue&type=script&lang=ts&"
export * from "./EllipsisText.vue?vue&type=script&lang=ts&"
import style0 from "./EllipsisText.vue?vue&type=style&index=0&id=93e92290&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-93e13427ca/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93e92290",
  null
  
)

export default component.exports